/* 404.css */
.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0; /* Background color for the error page */
}

.error-content {
  text-align: center;
}

.error-title {
  font-size: 4rem;
  margin-bottom: 20px;
  color: #333; /* Title color */
}

.error-description {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #555; /* Description color */
}

.error-link {
  font-size: 1.25rem;
  padding: 10px 20px;
  background-color: #007bff; /* Link background color */
  color: #fff; /* Link text color */
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.error-link:hover {
  background-color: #0056b3; /* Hover color */
}
