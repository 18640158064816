.plane-effect {
    width: 100%;
    aspect-ratio: 8/2;
    object-fit: cover;
    transition: transform 0.5s ease;
  }
  
  .image-div {
    transition: transform 2s ease;
    overflow: hidden;
  }
  
  .image-div.transformed {
    transform: scale(1.2);
  }
  
  .image-div.auto-animation {
    animation: autoTransform 9s ease infinite;
    overflow: hidden;
  }
  .image-div img {
    width: 100%;
    height: 600px;
    overflow: hidden;
  }
  .ppplan {
    overflow: hidden;
  }
  
  .booking_card {
    width: 100%;
    position: absolute !important;
    top: 40%;
  }
  
  @media (max-width: 1160px){
    .bookingCardDesktop{
      display: none;
    }
  }
  
  @media (min-width: 1160px){
    .bookingCardMobile{
      display: none;
    }
  }
  
  @media (max-width: 768px) {
    .image-div img {
      height: 300px;
    }
    .booking_card {
      position: relative !important;
      left: 0px !important;
      top: 0px !important;
      transform: translate(-50%,-50%)!important;
    }
  }
  
  @keyframes autoTransform {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }