.login_container {
	width: 400px;
	background-color: #fff;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	padding: 50px 50px 20px;
	margin: 50px auto;
}

.login_container .container_title {
	font-size: 1.5rem;
	margin-bottom: 20px;
}

.login_container .input {
	width: 100%;
	padding: 10px;
	margin-bottom: 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
}

.login_container .button {
	width: 100%;
	padding: 10px;
	background-color: #71AE4D;
	color: #fff;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	margin: 2px 0;
}
