.loaderx {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	top: 0;
	text-align: center;
	z-index: 9999;
	background: black;
	opacity: 0.7;
}

.svg-calLoader {
	width: 230px;
	height: 230px;
	margin-top: 300px;
	transform-origin: 115px 115px;
	animation: 1.4s linear infinite loader-spin;
}

.cal-loader__plane {
	fill: #71AE4D;
}

.cal-loader__path {
	stroke: #ffffff;
	animation: 1.4s ease-in-out infinite loader-path;
}

@keyframes loader-spin {
	to {
		transform: rotate(360deg);
	}
}

@keyframes loader-path {
	0% {
		stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
	}

	50% {
		stroke-dasharray: 0, 450, 10, 30, 10, 30, 10, 30, 10;
	}

	100% {
		stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
	}
}

.hyperModalWrapper___2sZCR.visible___uofxI {
	visibility: visible;
	opacity: 1;
	z-index: 999;
}

.buttons.navbookingarea {
	margin: auto !important;
}

.hyperDimmerWrapper___3pEXB {
	pointer-events: none;
}

.hyperDimmerWrapper___3pEXB {
	background-color: #000000;
	background-image: linear-gradient(315deg, #000000 0%, #414141 74%);
}

svg#sico {
	color: #71AE4D;
}

@media screen and (max-width: 1023px) {
	.hyperModalWrapper___2sZCR .hyperModalContentWrapper___1o7Ug {
		color: black !important;
		width: 100% !important;
		height: 100% !important;
		border-radius: 0px !important;
		overflow: hidden;
	}

	#bd > .column > svg {
		display: none;
	}

	#navtoptop {
		font-size: 11px !important;
		text-align: center;
		width: 60%;

		margin-left: 13px !important;
	}

	.divd {
		margin-top: -5px !important;
		font-size: 20px;
	}

	#alignthisformobile {
		height: 200px;
	}

	#bd {
		text-align: center !important;
	}

	.navbookingarea {
		display: flex !important;
	}

	.notbookingheadactive {
		background: none;
		color: white;
		font-weight: bold;
		border: none;
		border-radius: 0px;
		font-size: 0.9rem !important;
	}

	.bookingheadactive {
		background: none;
		color: white;
		font-weight: bold;
		border: none;
		border-bottom: 4px solid #71AE4D;
		border-radius: 0px;
		font-size: 0.9rem !important;
	}

	.checkthisbuttonformobile {
		width: 4.125rem !important;
		height: 4.125rem !important;
		position: relative !important;
		top: -17px !important;

		border: 1.5px solid white !important;
	}

	.makecolorgreen {
		color: #71AE4D !important;
	}

	.removethispadding {
		padding: 0px !important;
	}

	.aligncenteronmobile {
		text-align: center;
	}

	.list-header {
		font-weight: bold;
	}

	.footerlinks {
		padding-left: 0px !important;
	}

	.bsliders {
		display: none !important;
	}

	#boxest > .column {
		margin-left: 0.5px !important;
	}

	#recentnews {
		display: none !important;
	}

	.bslidermobile {
		display: block !important;
		float: right;
		position: static !important;
	}

	.groupthisbtn > .button {
		color: black !important;
		border: 2px solid black !important;
		float: right !important;
		position: relative;
		top: -20px;
	}

	#booknowbtn {
		position: relative;
		top: -230px !important;
		left: 100px !important;
		height: 140px !important;
		width: 140px !important;
		border-radius: 50%;
	}

	.holder {
		margin-top: 0.5rem !important;
		width: 97% !important;
	}

	#mt3 {
		margin-top: -2.4rem !important;
	}

	.media {
		margin-top: 0px !important;
	}

	.slbottom {
		height: 30px !important;
	}

	.navbar-brand,
	.navbar-tabs {
		background: #71AE4D !important;
	}

	#c1x {
		padding: 0.8rem !important;
		margin-top: 20px !important;
	}

	#slx {
		display: none;
	}

	.air .title {
		font-size: 29px !important;
	}

	#bd {
		display: inline-flex !important;
		padding: 8px !important;
	}

	#sbtn {
		position: static !important;
		float: none !important;
		display: inline;
		margin-left: -130px !important;
		margin-top: 40px !important;
	}

	#sbtn > .button {
		height: 38px !important;
		width: 20px !important;
		font-size: 23px;
	}

	svg#sico {
		font-size: 20px;
		margin-top: -5px;
	}

	#removethisdesk {
		display: block !important;
	}

	#removethis {
		display: none !important;
	}

	.air {
		height: 240px !important;
	}

	.navbar-item {
		color: white !important;
	}

	#slidr {
		margin-top: -12px !important;
	}

	#top {
		padding-bottom: 0px !important;
	}

	.navbar-menu {
		background: black !important;
		position: fixed;
		height: 100%;
		width: 100%;
		top: 0;
		padding: 20px;
	}

	.closenavmobile {
		background: #71AE4D;
		color: white;
		border-radius: 100%;
		height: 70px;
		position: fixed;
		bottom: -30px;

		top: -10;
		right: -25px;
		width: 70px;
		display: block !important;
		font-weight: bold;
		text-align: center;
		vertical-align: inherit;
		line-height: 60px;
		z-index: 999 !important;
	}

	.closenavmobile:before,
	.closenavmobile:after {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		border: 1px solid #71AE4D;
		border-radius: 50%;
	}

	.closenavmobile:before {
		animation: ripples 2s linear infinite;
	}

	.closenavmobile:after {
		animation: ripples 2s linear 1s infinite;
	}

	@keyframes ripples {
		0% {
			transform: scale(1);
		}

		50% {
			transform: scale(1.3);
			opacity: 1;
		}

		100% {
			transform: scale(1.6);
			opacity: 0;
		}
	}

	.navbar-start a {
		margin: 10px;
	}

	a.navbar-item.nav-hover {
		background: black;
	}
}

a {
	text-decoration: none;
	color: inherit;
}

.hero {
	color: white !important;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-right: 10px;
}

.header-career {
	background: linear-gradient(90deg, #000 0%, grey 100%) !important;
	border-bottom-left-radius: 5%;
	border-bottom-right-radius: 5%;
	position: absolute;
	top: 0;
	right: 0;
	width: 100dvw;
	height: 600px;
	z-index: -10;
}

.header-career-ii {
	background: linear-gradient(90deg, #000 0%, grey 100%) !important;
	/* border-bottom-left-radius: 5%; */
	/* border-bottom-right-radius: 5%; */
	/* position: absolute; */
	/* top: 0; */
	/* right: 0; */
	width: 100dvw;
	height: 100px;
	z-index: -10;
}

@media screen and (max-width: 1023px) {
	.hero.is-medium {
		z-index: 999 !important;
		/* background-color: #71AE4D !important; */
	}

	.hero-body span {
		padding-top: 10px !important;
	}
}

@media screen and (max-width: 768px) {
	.about-box {
		display: none !important;
	}

	#about-top {
		padding-left: 0 !important;
		padding-right: 0 !important;
		padding-top: 0 !important;
	}
}

#about-top {
	width: 1000px !important;
}

#mission,
#mission-image,
#vision,
#vision-image {
	height: 500px;
}

@media screen and (max-width: 465px) {
	#mission,
	#mission-image,
	#vision,
	#vision-image {
		height: 300px;
	}

	#gall p {
		font-size: 16px !important;
		padding-top: 50px !important;
	}

	#gall > .column {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	#gall h3 {
		font-size: 29px !important;
	}

	#with-blackbg {
		background: black !important;
	}

	#with-blackbg h1 {
		font-size: 28px;
	}

	.hero-body p {
		font-size: 14px !important;
		text-align: justify !important;
	}
}

.hero-body {
	display: "flex";
	justify-content: "flex-end";
	width: 100% !important;
}

.lower-link {
	display: none;
}

@media screen and (max-width: 1023px) {
	#lower-nav {
		display: none !important;
	}

	.lower-link {
		display: block;
	}
}

/* Style the accordion panel. Note: hidden by default */

.panel {
	padding: 0px 30px;
	background-color: white;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.2s ease-out;
}

.accordion:after {
	content: "\02795";
	/* Unicode character for "plus" sign (+) */
	font-size: 19px;
	color: #777;
	float: right;
	margin-left: 5px;
}

.activeacc:after {
	content: "\2796";
	/* Unicode character for "minus" sign (-) */
}

@media screen and (max-width: 648px) {
	.login-bg {
		padding-right: 0px !important;
		padding-left: 0px !important;
		padding-top: 0px;
		padding-bottom: 0px;
	}

	.login-in {
		margin-right: 0px !important;
		margin-left: 0px !important;
		margin-top: 0px !important;
		margin-bottom: 0px !important;
		padding: 0 10px;
	}

	#login-inner {
		padding-right: 0px !important;
		padding-left: 0px !important;
		margin-top: 0px !important;
		margin-bottom: 0px !important;
		padding: 0 10px;
	}

	.login-form-container {
		padding-top: 5px !important;
		padding-bottom: 5px !important;
	}

	#login-inner h2 {
		font-size: 30px !important;
	}

	#login-inner #other_textx {
		font-size: 15px !important;
	}

	.big-button {
		background-color: #71AE4D;
		padding: 15px;
		width: 80%;
		margin-left: auto !important;
		margin-right: auto !important;
		color: #fff;
		cursor: pointer;
		border-radius: 5px;
	}
}

@media screen and (max-width: 716px) {
	.hero-body p {
		font-size: 15px;
		text-align: justify;
	}
}

.hero-ul {
	list-style: none;
	margin: 0 0 1em;
	padding: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.hero-ul li:not(:last-child) {
	margin-right: 2em;
}

@media only screen and (min-width: 1025px) {
	.hero-ul li {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
}

@media only screen and (max-width: 1024px) {
	.hero-ul li {
		text-align: center;
	}
}

.hero-ul-text h4 {
	margin-bottom: 0.25em;
	line-height: 1.2;
	line-height: 1.33333;
	font-size: 30px !important;
	font-weight: bold;
}

.hero-ul-text span {
	margin-bottom: 0.25em;
	line-height: 1.2;
	line-height: 1.33333;
	font-size: 15px !important;
	font-weight: bold;
}

.hero-ul-text {
	margin-left: 5px;
	color: #fff !important;
	line-height: 1.2;
}

.car-btn {
	background: linear-gradient(0deg, #ffffff, #ffffff),
		linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%), #fcfcfc;
	border-radius: 65px;
	color: #71AE4D !important;
	padding: 10px 25px 10px 25px;
}

/* Style the overall container */
#tabscontainer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	position: relative;
	margin: -70px 0 30px 0;
	padding: 0;
	max-width: 100%;
}

@media (max-width: 768px) {
	#tabscontainer {
		margin: 70px 0 30px 0;
	}
}

/* Set the font-family for all text within the #tabscontainer */

/* Style the radio group that corresponds to the tabs */
#tabscontainer > [name="radiogroupfortabs"] {
	position: absolute;
	visibility: hidden;
}

/* Set Flexbox ordering of radio items within the #tabscontainer.  A unique rule has to be created for each tab. */
#tabscontainer > #radiofortab1 {
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
}

#tabscontainer > #radiofortab2 {
	-webkit-box-ordinal-group: 3;
	-webkit-order: 2;
	-ms-flex-order: 2;
	order: 2;
}

#tabscontainer > #radiofortab3 {
	-webkit-box-ordinal-group: 4;
	-webkit-order: 3;
	-ms-flex-order: 3;
	order: 3;
}

#tabscontainer > #radiofortab4 {
	-webkit-box-ordinal-group: 5;
	-webkit-order: 4;
	-ms-flex-order: 4;
	order: 4;
}

/* Style all radio group LABELS (by class) to look like tabs.  The currently selected tab is re-styled by another rule near the end.  Could use the background-image attribute here instead of colors in order to give the tabs any appearance desired.  If doing this, then would have to create a separate rule for each tab. */
#tabscontainer > [id^="tab-label"] {
	width: 24.2%;
	position: relative;
	top: 0px;
	text-align: center;
	max-height: 18px;
	margin: 4px 2px 0 0;
	display: inline-block;
	padding: 18px 21px 36px 21px;
	border-radius: 5px 5px 0 0;
	font-size: 18px;
	font-weight: bold;
	text-transform: uppercase;
	background: #5f5f5f !important;
	cursor: pointer;
	color: #fff;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

@media screen and (max-width: 991px) {
	#tabscontainer > [id^="tab-label"] {
		font-size: 16px;
		width: 24.1%;
	}
}

@media screen and (max-width: 798px) {
	#tabscontainer > [id^="tab-label"] {
		font-size: 15px;
		width: 24.1%;
	}
}

@media screen and (max-width: 686px) {
	#tabscontainer > [id^="tab-label"] {
		font-size: 18px;
		width: 100%;
	}
}

#tabscontainer > input[type="radio"]:checked ~ label {
	background: none;
	border-bottom: none !important;
}

/* Style unselected tabs (INPUT element's label) when the pointer hovers over them.  Could use the background-image attribute here instead of colors in order to give the tab any appearance. */
#tabscontainer > [id^="tab-label"]:hover {
	background: #ffffff;
	color: black;
}

/* Style all of the content DIVs including setting DISPLAY to None to start with.  The DIV corresponding to the currently selected tab is displayed by the rule near the end. */
#tabscontainer > [id^="tab-content"] {
	-webkit-box-ordinal-group: 999;
	-webkit-order: 999;
	-ms-flex-order: 999;
	order: 999;
	/* Set to a high value - just has to be at least one more than the number of tabs */
	display: none;
	z-index: 2;
	top: 48px;
	width: 100%;
	min-height: 5em;
	overflow: hidden;
	padding: 10px 25px 25px 25px;
	background: #ffffff;
	margin-top: -1px;
	border-radius: 0 5px 5px 5px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* Style the currently selected tab (checked INPUT element's label) by first selecting the lone checked item from the radiogroup for the tabs and then select the label (by class) that follows the checked INPUT element (INPUT element within the named group - radiogroupfortabs) in order to apply the following effects to just the selected tab/label.  Could use the background-image attribute here instead of colors in order to give the tab any appearance. */
#tabscontainer > [name="radiogroupfortabs"]:checked + [id^="tab-label"] {
	z-index: 4 !important;
	margin-top: 0px !important;
	padding-top: 16px !important;
	background: #ffffff !important;
	color: #71AE4D !important;
	border-color: #000 #000 #c9c9c9 #000 !important;
}

/* Display the content DIV that corresponds to the selected tab (because of the limitations of CSS selectors, this could not be done with a single rule.  A unique rule has to be created for each tab/tab content within the tab set.) */
#tabscontainer > #radiofortab1:checked ~ #tab-content1 {
	display: block;
}

#tabscontainer > #radiofortab2:checked ~ #tab-content2 {
	display: block;
}

#tabscontainer > #radiofortab3:checked ~ #tab-content3 {
	display: block;
}

#tabscontainer > #radiofortab4:checked ~ #tab-content4 {
	display: block;
}

/* Style a container within the 4th tab to hold media items.  Could have also just put this styling directly on the tab-content4 div instead of creating the #mediacontainer div.  And, while the following are fully name-spaced for explanatory purposes, only the #mediacontainer item is actually needed as long as it is unique on the page. */
#tabscontainer #tab-content4 #mediamatrix {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

#tabscontainer #tab-content4 #mediamatrix .mediaitem {
	min-width: 21%;
	margin: 15px 0.9% 0;
	padding: 5px;
	border-radius: 10px;
	background-color: #ddeeff;
	text-align: center;
}

#tabscontainer #tab-content4 #mediamatrix .mediaitem .mediaitemthumb {
	margin-bottom: 5px;
	min-height: 50px;
}

#tabscontainer #tab-content4 #mediamatrix .mediaitem .mediaitemcaption {
	min-height: 20px;
}

.car-card {
	border: 1px solid #c4c4c4;
	box-sizing: border-box;
	border-radius: 26px;
	text-align: center;
	padding: 30px 15px;
	cursor: pointer;
}

.car-card:hover {
	border: 2px solid #71AE4D;
}

.career-card-1 {
	height: 150px;
	background-image: url(./images/wom.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}

.career-card-2 {
	height: 150px;
	background-image: url(./images/wom.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}

.career-card-3 {
	height: 150px;
	background-image: url(./images/wom.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.cd-normal,
.cd-hover {
	/* flex: 1;
    padding: 0 25px; */
}

.cd-demo-title {
	color: gray;
	margin: 0 0 15px;
	font-size: 18px;
	font-weight: bold;
	text-transform: uppercase;
}

.cd-module {
	height: 400px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
	transition: all 0.3s linear 0s;
	overflow: hidden;
	position: relative;
}

.cd-module:hover {
	box-shadow: 0 1px 35px 0 rgba(0, 0, 0, 0.3);
}

.cd-thumbnail {
	position: relative;
	overflow: hidden;
	transition: all 0.3s;
	background: url(./images/wom.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	height: 100%;
}

.cd-thumbnail img {
	width: 120%;
	transition: all 0.3s;
	height: 100%;
}

.cd-thumbnail img {
	display: none !important;
}

.cd-module:hover .cd-thumbnail {
	transform: scale(1.1);
	opacity: 0.6;
}

.cd-thumbnail .cd-date {
	position: absolute;
	top: 20px;
	right: 20px;
	background: #71AE4D;
	padding-top: 10px;
	color: #fff;
	font-weight: bold;
	border-radius: 100%;
	height: 55px;
	width: 55px;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 12px;
	text-transform: uppercase;
}

.cd-date div:first-child {
	font-size: 18px;
	line-height: 1.2;
}

.cd-content {
	position: absolute;
	width: 100%;
	height: 200px;
	bottom: 0;
	background: #fff;
	padding: 30px;
	transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
}

.cd-module:hover .cd-content {
	height: 300px;
}

.cd-content .cd-category {
	position: absolute;
	top: -34px;
	left: 0;
	color: #fff;
	text-transform: uppercase;
	background: #71AE4D;
	padding: 10px 15px;
	font-weight: bold;
}

.cd-title {
	margin: 0;
	padding: 0 0 10px;
	color: #333333;
	font-size: 24px;
	font-weight: 700;
}

.cd-sub-title {
	margin: 0;
	padding: 0 0 20px;
	color: #e74c3c;
	font-size: 20px;
	font-weight: 400;
}

.cd-description {
	color: #666666;
	font-size: 14px;
	line-height: 1.8em;
	height: 0;
	opacity: 1;
	transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
	overflow: hidden;
}

.cd-module:hover .cd-description {
	height: 100px;
}

.cd-meta {
	margin: 20px 0px 10px 0px;
	color: #999999;
	padding-bottom: 10px !important;
}

.titleblog {
	padding-bottom: 4px;
	border-bottom: solid 5px #71AE4D;
	display: inline-block;
}

#booknowbtn:before,
#booknowbtn:after {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border: 1px solid #71AE4D;
	border-radius: 50%;
}

#booknowbtn:before {
	animation: ripple 2s linear infinite;
}

#booknowbtn:after {
	animation: ripple 2s linear 1s infinite;
}

.media {
	margin-top: -30px;
}

@keyframes ripple {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.3);
		opacity: 1;
	}

	100% {
		transform: scale(1.6);
		opacity: 0;
	}
}

#byt {
	padding-bottom: 80px !important;
	background: #000000 !important;
	background-repeat: no-repeat !important;
	background-position: 0px !important;
	background-image: url(./images/bgfootertop.png),
		url(./images/bgfooterbottom.png) !important;
	background-position: left center, right center !important;
	background-repeat: no-repeat, no-repeat !important;
}

#mainnav {
	padding: 0px 110px !important;
	margin-right: auto !important;
	margin-left: auto !important;
	font-weight: bold !important;
}

.nav-hover {
	font-size: 1em !important;
	color: #ddd !important;
}

#c1x {
	padding: 8rem;
	margin-top: -27px;
}

*,
body,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Poppins", sans-serif !important;
}

body {
	background: #fff;
}

@media screen and (min-width: 1024px) {
	.navbar-menu {
		padding-left: 30px !important;
		padding-right: 30px !important;
	}
}

.mg-auto {
	margin: auto !important;
}

.mg-x-auto {
	margin-left: auto !important;
	margin-right: auto !important;
}

.mg-y-auto {
	margin-top: auto !important;
	margin-bottom: auto !important;
}

.text-center {
	text-align: center !important;
}

.columns {
	max-width: 100% !important;
	width: 100% !important;
	margin-left: auto !important;
	margin-right: auto !important;
}

.arrow {
	height: 40px !important;
	width: auto !important;
	margin-top: 3px;
	margin-left: -15px;
	cursor: pointer;
}

.select {
	width: 100% !important;
}

.form-control {
	width: 100% !important;
	width: unset !important;
}

.bottom-border:focus {
	border-top: none !important;
	border-right: none !important;
	border-left: none !important;
	border-color: #f0f0f0 !important;
	box-shadow: none !important;
	background-color: transparent !important;
}

.bottom-border:active {
	border-top: none !important;
	border-right: none !important;
	border-left: none !important;
	border-bottom: none !important;
	box-shadow: none !important;
	background-color: transparent !important;
}

#top {
	background-image: url("./images/Group 125.png");
	background-size: cover;
	background-color: black;
	background-position: bottom;
	color: #fff !important;
}

#top input select {
	color: #fff !important;
}

@media screen and (min-width: 1024px) {
	.navbar-item {
		color: #fff !important;
	}
}

#nav {
	background-color: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px 20px;
	color: white;
}

#nav > div {
	border-bottom: 1px solid rgba(255, 255, 255, 0.5);
	/* padding: 10px 0; */
}

#nav > div a {
	text-decoration: none;
	color: white !important;
}

.navbar-burger {
	color: #fff !important;
}

.navbar-burger:hover {
	color: #f0f0f0 !important;
}

.pd-50 {
	padding: 50px 10px;
}

@media (max-width: 769px) {
	.pd-50 {
		padding: 10px 5px;
	}
}

.select:not(.is-multiple):not(.is-loading)::after {
	border-color: #71AE4D;
	right: 1.125em;
	z-index: 4;
}

.navbar-link:not(.is-arrowless)::after {
	border-color: #71AE4D;
	margin-top: -0.375em;
	right: 1.125em;
}

.nav-hover:hover {
	border-bottom: 3px solid #71AE4D;
	padding-bottom: 2px;
}

optgroup {
	font-size: 14px;
	padding: 5px;
	background: #5c5c5c;
}

option {
	color: #000000;
}

.bdc {
	margin-top: 13px;
	padding-right: 30px;
	padding-left: 30px;
}

.bdc li {
	padding: 0 50px;
}

.divd {
	overflow: visible;
	margin-bottom: -5px;
	font-size: 30px;
}

.air {
	background-image: url("./images/plane.jpeg");
	background-size: cover;

	background-position: 50% 50%;
	background-repeat: no-repeat;
	padding-bottom: 0px;
	padding-right: 0px;
	padding-left: 0px;
	height: 600px;
	animation: shrink 10s infinite alternate;
}

.air .title {
	width: 40%;
	left: 180px;
	top: 868px;
	text-shadow: 1px 2px 3px gray;
	font-weight: bold;
	font-size: 45px;
	padding: 10px;
	color: #e7e7e7;
	margin-top: 40px;
}

@media (max-width: 679px) {
	.air .title {
		width: 100%;
		padding: 10px;
	}
}

#bd {
	padding: 20px 160px;
	background: #5f5f5f;
	margin-right: auto;
	margin-left: auto;
	font-size: 12px;
}

#bd column {
	font-size: 20px !important;
}

.ques {
	width: 20px !important;
	margin-bottom: -4px;
	margin-left: 5px;
}

.has-rounded-border {
	border-radius: 10px;
}

.p-0 {
	padding: 0;
}

.recent-news {
	padding: 10px 60px;
}

@media (max-width: 679px) {
	.recent-news {
		padding: 10px 5px;
	}
}

.image.is-4by3 {
	padding-top: 86% !important;
}

.fmt {
	font-size: 2.5rem;
	line-height: 3.125rem;
}

#top [type="checkbox"] {
	top: 0.5rem;
	left: 1rem;
	width: 1.125rem;
	height: 1.25rem;
}

#top label {
	position: relative !important;
	line-height: 1.5rem !important;
}

.checkboxes label {
	display: inline-block;
	padding-right: 10px;
	white-space: nowrap;
}

.checkboxes input {
	vertical-align: -2.8px;
}

.checkboxes label span {
	vertical-align: middle;
}

.searchops {
	padding-right: 15px;
	padding-left: 15px;
}

@media (max-width: 679px) {
	.searchops {
		padding-right: 25px;
		padding-left: 0px;

		margin: 10px 0px;
	}
}

#booknowbtn {
	position: relative;
	top: -380px;
	left: 300px;
	height: 140px;
	width: 140px;
	border-radius: 50%;
}

#sliderbbuttons {
	position: relative;
	top: -260px;
	left: 510px;
}

#sliderbbuttons > .button {
	background: none;
	color: white;
	border: 2px solid white;
	width: 50px;
	border-radius: 0px;
}

.holder {
	width: 100%;
	padding-right: 5px;
	padding-left: 5px;
	margin-right: auto;
	margin-left: auto;
}

@media (min-width: 375px) {
	.holder {
		max-width: 100%;
	}
}

@media (min-width: 768px) {
	.holder {
		max-width: 730px;
	}
}

@media (min-width: 992px) {
	.holder {
		max-width: 976px;
	}
}

@media (min-width: 1200px) {
	.holder {
		max-width: 1260px;
	}
}

.card-image-1 {
	height: 300px;
	background-image: url(./images/wom.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	border-top-right-radius: 15px;
	border-top-left-radius: 15px;
}

.card-image-2 {
	height: 300px;
	background-image: url(./images/wom.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	border-top-right-radius: 15px;
	border-top-left-radius: 15px;
}

.box-shadow-none {
	box-shadow: none !important;
	height: 100%;
	background: #ecebeb;
}

.boxes {
	margin: auto;
	padding: 50px;
	background: #484848;
}

/*Checkboxes styles*/
input[type="checkbox"] {
	display: none;
}

input[type="checkbox"] + label {
	display: block;
	position: relative;
	padding-left: 30px;
	margin-bottom: 20px;
	font: 14px/20px "Open Sans", Arial, sans-serif;
	color: #ddd;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}

input[type="checkbox"] + label:last-child {
	margin-bottom: 0;
}

input[type="checkbox"] + label:before {
	content: "";
	display: block;
	width: 20px;
	height: 20px;
	border: 1px solid #fff;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0.6;
	-webkit-transition: all 0.12s, border-color 0.08s;
	transition: all 0.12s, border-color 0.08s;
}

input[type="checkbox"]:checked + label:before {
	width: 10px;
	top: -5px;
	left: 5px;
	border-radius: 0;
	opacity: 1;
	border-top-color: transparent;
	border-left-color: transparent;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.icon {
	border: 3px #fff solid;

	width: 100%;
	height: 100%;
	margin: 10px;
	padding: 60px 0px;
}

.card {
	border-radius: 15px !important;
}

.tabs {
	display: flex;
	flex-direction: column;
}

.tab-content div {
	display: none;
}

.tabs li {
	background: #ecebeb;
	width: 100%;
	padding-top: 0px;
	padding-bottom: 0px;
	margin-left: 3px;
}

.tabs ul {
	background: #71AE4D;
}

.tabs ul li a {
	height: 90px !important;
}

.tabs li.is-active a {
	border-bottom: none;
	color: #71AE4D;
}

.tabs ul .is-active {
	background: #fff;
	width: 100%;
	padding-top: 0px;
	padding-bottom: 0px;
	margin: 0px 0px 0px 0px;
}

.tab-content div:first-child {
	display: flex;
}

.tab-content .column {
	padding: 0px;
}

.learn-more {
	padding: 20px 40px 20px 40px;
	border: #fff 3px solid;
	color: #fff;
	background-color: #71AE4D;
	cursor: pointer;
	font-weight: bold;
}

.learn-more:hover {
	background-color: #fff;
	border: #fff 3px solid;
	color: #71AE4D;
}

.list-unordered > li {
	margin-left: auto;
	margin-right: auto;
	text-align: left !important;
	margin-bottom: 6px;
}

.list-unordered {
	margin-left: auto;
	margin-right: auto;
}

.list-header {
	font-size: 20px !important;
	margin-left: auto;
	margin-right: auto;
	text-align: left !important;
}

.round-btn {
	width: 3.125rem;
	height: 3.125rem;
	cursor: pointer;
	border-width: 0;
	border-radius: 50% !important;
	color: #fff;
	background-color: #71AE4D;
	border-color: #71AE4D;
	display: inline-block;
	font-weight: 400;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.25rem;
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
	position: relative;
}

.round-btn:before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	line-height: 3.125rem;
	color: #fff;
}

.bsliders {
	display: inline;
}

.round-btn:hover {
	color: #fff;
	background-color: #579739;
	border-color: #579739;
}

.round-btn:focus {
	border-radius: 50% !important;
	box-shadow: 0 0 0 8px rgba(254, 254, 254, 0.8), 0 0 0 10px #71AE4D !important;
	background-color: #579739 !important;
	border-color: #579739 !important;
	outline: none !important;
}

#sbtn {
	width: 20% !important;
	float: right;
	bottom: 0;
	top: 50%;
	position: relative;
}

#sbtn > .button {
	background: none;
	color: white;
	border: 2px solid white;
	width: 50px;
	border-radius: 0px;
}

#removethisdesk {
	display: none;
}

.navbookingarea {
	display: none;
}

#two-arrow {
	font-size: 40px;
	margin-top: -6px;
	color: #71AE4D;
}

#bd-bottom-w-70 {
	width: 70% !important;
}

#lower-nav {
	background-color: transparent !important;
}

@media screen and (min-width: 1024px) {
	#navbarBasicExample {
		border-bottom: #9e9b9b 0.1em solid !important;
		padding-left: 0 !important;
		padding-right: 0 !important;
		margin-left: 20px !important;
		margin-right: 40px !important;
		height: 50px !important;
	}
}

.big-button {
	background-color: #71AE4D;
	padding: 30px;
	width: 100%;
	margin-left: auto !important;
	margin-right: auto !important;
	color: #fff;
	cursor: pointer;
	border-radius: 5px;
	border: none;
}

.big-button:hover {
	color: #71AE4D !important;
	background-color: #fff;
}

.big-button:hover a {
	color: #71AE4D !important;
}

.big-button a {
	color: #fff;
	font-size: 26px;
}

@media screen and (max-width: 648px) {
	.login-bg {
		padding-right: 0px !important;
		padding-left: 0px !important;
		padding-top: 0px;
		padding-bottom: 0px;
	}

	.login-in {
		margin-right: 0px !important;
		margin-left: 0px !important;
		margin-top: 0px !important;
		margin-bottom: 0px !important;
		padding: 0 10px;
	}

	#login-inner {
		padding-right: 0px !important;
		padding-left: 0px !important;
		margin-top: 0px !important;
		margin-bottom: 0px !important;
		padding: 0 10px;
	}

	.big-button {
		background-color: #71AE4D;
		padding: 12px;
		width: 50%;
		margin-left: auto !important;
		color: #fff;
		cursor: pointer;
		border-radius: 5px;
	}
}

.mx-auto {
	margin-left: auto !important;
	margin-right: auto !important;
}

input.bottom-border:focus {
	border-top: none !important;
	border-right: none !important;
	border-left: none !important;
	box-shadow: none !important;
	outline: none;
	background-color: transparent !important;
}

input.bottom-border {
	color: #fff;
	font-size: 22px;
}

@media screen and (min-width: 769px) {
	.hero.is-medium .hero-body {
		padding: 6rem 1.5rem !important;
	}

	#hero-cr {
		padding: 1.5rem 1.5rem 6rem 1.5rem !important;
	}
}

#hero-cr {
	padding-top: 1.5rem !important;
}

/* Style the tab */
.tab-term {
	float: left;
	border: 1px solid #f1f1f1;
	width: 30%;
	height: auto;
}

/* Style the buttons that are used to open the tab content */
.tab-term button {
	display: block;
	background-color: #fff;
	color: #71AE4D;
	text-align: center;
	padding: 22px 16px;
	width: 100%;
	border: solid 2px #f1f1f1;
	outline: none;
	text-align: left;
	cursor: pointer;
	transition: 0.3s;
	font-size: 17px;
}

/* Change background color of buttons on hover */
.tab-term button:hover {
	background-color: #71AE4D;
	color: #fff;
}

/* Create an active/current "tab button" class */
.tab button.active {
	background-color: #71AE4D;
}

/* Style the tab content */
.tabcontent {
	float: left;
	padding: 30px 12px;
	width: 70%;
	height: auto;
	border: #f1f1f1 solid 2px;
	background: #ecebeb;
	border-left: none;
}

.tabcontent .content {
	padding: 20px;
}

.topr {
	padding-left: 20% !important;
	padding-right: 100px !important;
	font-size: 14px !important;
}

.groupbook {
	position: fixed;
	top: 50vh;
	left: 100;
	z-index: 999;
	transform: rotateZ(-90deg);
}

.groupbook > button {
	background: white;
	color: black;
	font-weight: bolder;
	border: 1px solid black;
}

@media screen and (max-width: 768px) {
	.tab-term {
		float: none !important;
		width: 100% !important;
	}

	.tabcontent {
		float: none !important;
		width: 100% !important;
	}
}

@media screen and (max-width: 990px) {
	.topr {
		padding-left: 10% !important;
		padding-right: 50px !important;
		font-size: 14px !important;
		margin-left: -30px !important;
	}
}

@media screen and (max-width: 768px) {
	.topr {
		padding-left: 10% !important;
		padding-right: 30px !important;
		font-size: 14px !important;
		margin-left: -30px !important;
	}

	/* margin-left: -30px !important; */
}

@media screen and (max-width: 590px) {
	.topr {
		padding-left: 0% !important;
		padding-right: 0 !important;
		font-size: 14px !important;
		margin-left: -30px !important;
	}

	#topr-cont {
		padding-left: 0% !important;
		padding-right: 0 !important;
		font-size: 14px !important;
		margin-left: -30px !important;
	}
}

.carr-icon-1 {
	height: 80px !important;
}

.carr-icon-2 {
	height: 75px !important;
}

@keyframes shrink {
	0% {
		background-size: 110% 110%;
	}

	100% {
		background-size: 150% 150%;
	}
}

@keyframes colorbox {
	0% {
		background: url("./images/air-hostess-removebg-preview.png");
		background-repeat: no-repeat;
		background-size: contain !important;
		background-position: right;
	}

	20% {
		background: url("./images/air-hostess-removebg-preview.png");
		background-repeat: no-repeat;
		background-position: right;
	}

	40% {
		background: url("./images/ksf-removebg-preview.png");
		background-repeat: no-repeat;
		background-position: right;
	}

	60% {
		background: url("./images/ksf-removebg-preview.png");
		background-repeat: no-repeat;
		background-position: right;
	}

	80% {
		background: url("./images/pilots-removebg-preview.png");
		background-repeat: no-repeat;
		background-position: right;
	}

	100% {
		background: url("./images/pilots-removebg-preview.png");
		background-repeat: no-repeat;
		background-position: right;
	}
}

#crr-hdr {
	animation-delay: 5s;
	animation: colorbox 15s infinite linear;
	animation-fill-mode: forwards;
}

#hero-cr .container {
	padding-left: 100px !important;
}

#hdr-1,
#hdr-2 {
	font-size: 23px !important;
}

@media screen and (max-width: 1024px) {
	#crr-hdr {
		background-position: right !important;
		background-size: contain !important;
		background-repeat: no-repeat !important;
		padding-top: 5px !important;
	}

	#hero-cr h1 {
		font-size: 32px !important;
	}

	#hdr-1,
	#hdr-2 {
		font-size: 23px !important;
	}

	#hero-cr img {
		height: 50px !important;
	}

	#hero-cr .container {
		padding-left: 20px !important;
	}

	#hdr-1 {
		padding-top: 0 !important;
	}

	#hero-cr span {
		font-size: 12px !important;
	}

	#hdr-2 {
		margin-top: 0 !important;
	}

	/* .hero-ul li:not(:last-child) {
    margin-right: 10px!important;
} */
	#discover-text-header {
		margin-top: 0 !important;
	}

	#hero-cr .buttons {
		margin-left: 2px !important;
		margin-right: 2px !important;
		margin-bottom: 30px !important;
		padding-left: 0 !important;
	}

	#carc-btn {
		margin-right: 0 !important;
		font-size: 19px !important;
	}

	#crc-btn {
		margin-left: 0 !important;
		font-size: 19px !important;
	}
}

@media screen and (max-width: 403px) {
	#crr-hdr {
		padding-top: 10px !imporant;
	}

	#crc-btn,
	#carc-btn {
		font-size: 12px !important;
	}
}

.navbar-item img#logo {
	margin-top: -25px;
	max-height: 100% !important;
	height: 100px !important;
	width: auto !important;
}

#logo_footer {
	max-height: 100% !important;
	height: 250px !important;
	width: auto !important;
}

@media screen and (max-width: 768px) {
	#logo_footer {
		height: 140px !important;
	}
}

a.job-card {
	display: grid;
	grid-template-columns: 80px auto 100px;
	grid-template-rows: 40px 35px 40px;
	width: 100%;
	position: relative;
	border: 1px solid #e3e3e3;
	margin-left: auto;
	margin-right: auto;
	padding: 24px;
	border-radius: 20px;
}

a.job-card:hover,
a.job-card:focus {
	background-color: rgba(0, 166, 194, 0.03);
	border-color: #b2e4ec;
}

.company-logo-img {
	grid-area: 1 / 1 / 2 / 2;
	background-color: #fff;
	border: 1px solid #e3e3e3;
	height: 80px;
	width: 80px;
	box-sizing: border-box;
	position: relative;
	padding: 5px;
}

.company-logo-img img {
	max-height: calc(100% - 10px);
	max-width: calc(100% - 10px);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.job-title {
	grid-area: 1 / 2 / 2 / 3;
	font-size: 23px;
	align-self: start;
	font-weight: 500;
	margin-top: 5px;
	padding: 0 24px;
}

.company-name {
	grid-area: 2 / 2 / 3 / 3;
	align-self: center;
	font-size: 14px;
	color: #777;
	margin-bottom: 5px;
	margin-top: 5px;
	padding: 0 24px;
}

.skills-container {
	grid-area: 3 / 2 / 4 / 3;
	align-self: center;
	padding-top: 10px;
	padding: 0 24px;
}

.skill {
	display: inline;
	color: #00a6c2;
	border-radius: 2px;
	background-color: rgba(0, 166, 194, 0.05);
	border: 1px solid rgba(0, 166, 194, 0.15);
	padding: 5px 8px;
	font-size: 12px;
}

.job-card button {
	display: block;
	width: 100%;
	cursor: pointer;
	border: 0;
	border-radius: 4px;
	font-size: 14px;
	padding: 6px 12px;
	z-index: 2;
}

.apply {
	grid-area: 1 / 3 / 2 / 4;
	background-color: #1ab059;
	color: #fff;
}

.save {
	grid-area: 3 / 3 / 4 / 4;
	background-color: #fff;
	border: 1px solid #a4a5a8;
	color: #777;
}

.job-card a {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 1;
}

.mobile-wrapper {
	margin-top: 50px;
	max-width: 400px;
}

.mobile-wrapper article {
	grid-template-columns: 60px auto;
	grid-template-rows: 35px 25px auto 40px;
	width: calc(100% - 32px);
	padding: 16px;
}

.mobile-wrapper .company-logo-img {
	grid-area: 1 / 1 / 3 / 2;
	height: 60px;
	width: 60px;
}

.mobile-wrapper .job-title {
	grid-area: 1 / 2 / 2 / 2;
	padding: 8px 16px 0 16px;
}

.mobile-wrapper .company-name {
	grid-area: 2 / 2 / 3 / 2;
	padding: 0 16px;
}

.mobile-wrapper .skills-container {
	grid-area: 3 / 1 / 4 / 3;
	padding: 16px 0;
}

.mobile-wrapper .btn-container {
	grid-area: 4 / 1 / 5 / 3;
	display: flex;
}

.mobile-wrapper .btn-container button {
	height: 38px;
	flex: 1;
	width: 0;
}

.mobile-wrapper .btn-container .apply {
	margin-right: 10px;
}

header.masthead {
	padding-top: 0.2rem;
	padding-bottom: 4rem;
	margin-bottom: 3rem;
	background: #a3bded;
	padding-bottom: 4rem;
	margin-bottom: 3rem;
	background: -moz-linear-gradient(
			top,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0) 59%,
			rgba(0, 0, 0, 0.65) 100%
		),
		url("./airport-4120835_1920.jpg") no-repeat;
	background: -webkit-gradient(
			linear,
			left top,
			left bottom,
			color-stop(0%, rgba(0, 0, 0, 0)),
			color-stop(59%, rgba(0, 0, 0, 0)),
			color-stop(100%, rgba(0, 0, 0, 0.65))
		),
		url("./airport-4120835_1920.jpg") no-repeat;
	background: -webkit-linear-gradient(
			top,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0) 59%,
			rgba(0, 0, 0, 0.65) 100%
		),
		url("./airport-4120835_1920.jpg") no-repeat;
	background: -o-linear-gradient(
			top,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0) 59%,
			rgba(0, 0, 0, 0.65) 100%
		),
		url("./airport-4120835_1920.jpg") no-repeat;
	background: -ms-linear-gradient(
			top,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0) 59%,
			rgba(0, 0, 0, 0.65) 100%
		),
		url("./airport-4120835_1920.jpg") no-repeat;
	background: linear-gradient(
			to bottom,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0) 59%,
			rgba(0, 0, 0, 0.65) 100%
		),
		url("./airport-4120835_1920.jpg") no-repeat;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

header.masthead .site-heading {
	padding: 100px 0 50px;
	color: black !important;
}

header.masthead .site-heading h1 {
	font-size: 2.3rem;
	padding-left: 5px;
	padding-right: 5px;
	color: #fff !important;
	background: #71AE4D;
	display: inline-block;
	font-weight: bolder;
}

header.masthead .site-heading .subheading {
	display: block;
	font-weight: 300;
	margin: 0.625rem 0 0;
	color: #fff;
	background: #fff;
	color: #71AE4D;
	display: inline-block;
	padding-left: 5px;
	padding-right: 5px;
}

@media screen and (max-width: 645px) {
	.my-2.job-card {
		display: block !important;
	}

	.company-name,
	.job-title {
		padding: 5px 0px !important;
	}

	.skills-container {
		padding: 10px 0px !important;
	}

	.job-card button {
		margin-bottom: 2px;
	}
}

.menu-list a.is-active {
	background-color: #71AE4D !important;
	color: #fff;
}

.navbar-link:not(.is-arrowless)::after {
	border-color: #71AE4D !important;
	margin-top: -0.5em !important;
	right: 1.125em;
}

.modal-card {
	overflow: auto !important;
}

:root {
	--form-height: 550px;
	--form-width: 900px;
	/*  Sea Green */
	/*  Sea Green */
	--left-color: #71AE4D;
	/*  Light Blue  */
	--right-color: #71AE4D;
}

.containerlogin {
	width: var(--form-width);
	height: var(--form-height);
	position: relative;
	margin: auto;
	box-shadow: 2px 10px 40px rgba(22, 20, 19, 0.4);
	border-radius: 10px;
	margin-top: 50px;
	background: white;
	position: fixed;
	z-index: 999;
	margin-top: 110px;
	margin-left: -100px;
}

/* 
----------------------
Overlay
----------------------
*/
.overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 100;
	background-image: linear-gradient(
		to right,
		var(--left-color),
		var(--right-color)
	);
	border-radius: 10px;
	color: white;
	clip: rect(0, 385px, var(--form-height), 0);
}

.open-sign-up {
	animation: slideleft 1s linear forwards;
}

.open-sign-in {
	animation: slideright 1s linear forwards;
}

.overlay .sign-in,
.overlay .sign-up {
	/*  Width is 385px - padding  */
	--padding: 40px;
	width: calc(385px - var(--padding) * 2);
	height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	padding: 0px var(--padding);
	text-align: center;
}

.overlay .sign-in {
	float: left;
}

.overlay-text-left-animation {
	animation: text-slide-in-left 1s linear;
}

.overlay-text-left-animation-out {
	animation: text-slide-out-left 1s linear;
}

.overlay .sign-up {
	float: right;
}

.overlay-text-right-animation {
	animation: text-slide-in-right 1s linear;
}

.overlay-text-right-animation-out {
	animation: text-slide-out-right 1s linear;
}

.overlay h1 {
	margin: 0px 5px;
	font-size: 2.1rem;
}

.overlay p {
	margin: 20px 0px 30px;
	font-weight: 200;
}

/* 
------------------------
Buttons
------------------------
*/
.switch-button,
.control-button {
	cursor: pointer;
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 140px;
	height: 40px;
	font-size: 14px;
	text-transform: uppercase;
	background: none;
	border-radius: 20px;
	color: white;
}

.switch-button {
	border: 2px solid;
}

.control-button {
	border: none;
	margin-top: 15px;
}

.switch-button:focus,
.control-button:focus {
	outline: none;
}

.control-button.up {
	background-color: var(--left-color);
}

.control-button.in {
	background-color: var(--right-color);
}

/* 
--------------------------
Forms
--------------------------
*/
.form {
	width: 100%;
	height: 100%;
	position: absolute;
	border-radius: 10px;
}

.form .sign-in,
.form .sign-up {
	--padding: 50px;
	position: absolute;
	/*  Width is 100% - 385px - padding  */
	width: calc(var(--form-width) - 385px - var(--padding) * 2);
	height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	padding: 0px var(--padding);
	text-align: center;
}

/* Sign in is initially not displayed */
.form .sign-in {
	display: none;
}

.form .sign-in {
	left: 0;
}

.form .sign-up {
	right: 0;
}

.form-right-slide-in {
	animation: form-slide-in-right 1s;
}

.form-right-slide-out {
	animation: form-slide-out-right 1s;
}

.form-left-slide-in {
	animation: form-slide-in-left 1s;
}

.form-left-slide-out {
	animation: form-slide-out-left 1s;
}

.form .sign-in h1 {
	color: var(--right-color);
	margin: 0;
}

.form .sign-up h1 {
	color: var(--left-color);
	margin: 0;
}

.social-media-buttons {
	display: flex;
	justify-content: center;
	width: 100%;
	margin: 15px;
}

.social-media-buttons .icon {
	width: 40px;
	height: 40px;
	border: 1px solid #dadada;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 10px 7px;
	padding: 5px !important;
}

.autharea .small {
	font-size: 13px;
	color: grey;
	font-weight: 200;
	margin: 5px;
}

.social-media-buttons .icon svg {
	width: 25px;
	height: 25px;
}

#sign-in-form input,
#sign-up-form input,
#sign-up-form select {
	margin: 12px;
	font-size: 14px;
	padding: 15px;
	width: 260px;
	font-weight: 300;
	border: none;
	background-color: #e4e4e494;
	letter-spacing: 1.5px;
	padding-left: 20px;
}

#sign-in-form input::placeholder {
	letter-spacing: 1px;
}

.forgot-password {
	font-size: 12px;
	display: inline-block;
	border-bottom: 2px solid #efebeb;
	padding-bottom: 3px;
}

.forgot-password:hover {
	cursor: pointer;
}

/* 
---------------------------
Animation
---------------------------
*/
@keyframes slideright {
	0% {
		clip: rect(0, 385px, var(--form-height), 0);
	}

	30% {
		clip: rect(0, 480px, var(--form-height), 0);
	}

	/*  we want the width to be slightly larger here  */
	50% {
		clip: rect(
			0px,
			calc(var(--form-width) / 2 + 480px / 2),
			var(--form-height),
			calc(var(--form-width) / 2 - 480px / 2)
		);
	}

	80% {
		clip: rect(
			0px,
			var(--form-width),
			var(--form-height),
			calc(var(--form-width) - 480px)
		);
	}

	100% {
		clip: rect(
			0px,
			var(--form-width),
			var(--form-height),
			calc(var(--form-width) - 385px)
		);
	}
}

@keyframes slideleft {
	100% {
		clip: rect(0, 385px, var(--form-height), 0);
	}

	70% {
		clip: rect(0, 480px, var(--form-height), 0);
	}

	/*  we want the width to be slightly larger here  */
	50% {
		clip: rect(
			0px,
			calc(var(--form-width) / 2 + 480px / 2),
			var(--form-height),
			calc(var(--form-width) / 2 - 480px / 2)
		);
	}

	30% {
		clip: rect(
			0px,
			var(--form-width),
			var(--form-height),
			calc(var(--form-width) - 480px)
		);
	}

	0% {
		clip: rect(
			0px,
			var(--form-width),
			var(--form-height),
			calc(var(--form-width) - 385px)
		);
	}
}

@keyframes text-slide-in-left {
	0% {
		padding-left: 20px;
	}

	100% {
		padding-left: 50px;
	}
}

@keyframes text-slide-in-right {
	0% {
		padding-right: 20px;
	}

	100% {
		padding-right: 50px;
	}
}

@keyframes text-slide-out-left {
	0% {
		padding-left: 50px;
	}

	100% {
		padding-left: 20px;
	}
}

@keyframes text-slide-out-right {
	0% {
		padding-right: 50px;
	}

	100% {
		padding-right: 20px;
	}
}

@keyframes form-slide-in-right {
	0% {
		padding-right: 100px;
	}

	100% {
		padding-right: 50px;
	}
}

@keyframes form-slide-in-left {
	0% {
		padding-left: 100px;
	}

	100% {
		padding-left: 50px;
	}
}

@keyframes form-slide-out-right {
	0% {
		padding-right: 50px;
	}

	100% {
		padding-right: 80px;
	}
}

@keyframes form-slide-out-left {
	0% {
		padding-left: 50px;
	}

	100% {
		padding-left: 80px;
	}
}

#logo_footer {
	max-height: 100% !important;
	height: 100px !important;
	width: auto !important;
}

@media screen and (max-width: 768px) {
	#logo_footer {
		height: 140px !important;
	}
}

#ft_hr {
	height: 0.1em !important;
	background-color: #9e9b9b !important;
}

.lastrr{
	width: 100% !important;
}
