.greater-than-si {
	background-color: #c5cac6;
	width: 50px;
	position: absolute;
	right: 0px;
	bottom: 0px;
}
.text-overlay p {
	margin: 0; /* Remove default margin for the <p> element */
}
.main-div {
	background-color: #71AE4D;
}

.the-uper-part {
	position: relative;
	padding-bottom: 100px;
	background-repeat: no-repeat;
	background-size: cover;
	height: 250px;
}

.the-uper-part h6 {
	font-size: 20px;
	font-weight: 900;
}

.the-uper-part p {
	font-size: 14px;
	overflow-y: auto;
	height: 100px;
}

.fort-main-pa {
	position: absolute;
	top: 30px;
}
.greater-than-si:hover {
	background-color: black;
	cursor: pointer;
}
.fort-main-part {
	background-color: #10172B;
	width: fit-content !important;
	border-right: 5px solid #71AE4D;
}

.fort-main-part-text {
	width: fit-content;
	height: fit-content !important;
	opacity: 0;
	position: absolute;
	background-color: #71AE4D;
	left: 5px;
	right: 5px;
	top: 5px;
	display: grid;
	place-content: center;
	font-size: 19px;
	font-weight: 700;
	padding: 20px;
	transition: opacity 0.8s ease-in-out;
}

.ffo {
	font-size: 17px;
	font-weight: 800;
}
.the-uper-part:hover .fort-main-part-text {
	opacity: 1;
	cursor: pointer;
}

.priva {
	text-decoration: underline;
}
.cookies {
	text-decoration: underline;
	margin-left: 4px;
}
.last-main {
	background-color: #71AE4D;
}
.accept-cookies {
	border: 3px solid white;
	font-size: 14px;
}
.accept-all-cookies {
	font-size: 15px;
	background-color: white;
	color: black;
}
.accept-cookies:hover {
	cursor: pointer;
	background-color: black;
	color: white;
}
.accept-all-cookies:hover {
	cursor: pointer;
	color: white;
	background-color: black;
}
