.footer {
	width: auto;
	background: url("../images//Frame\ 2.png") no-repeat;
	background-size: cover;
	color: white;
	height: 100%;
}

.cover {
	padding-top: 40px;
	padding: 40px 11rem;
	height: 100%;
}

.footer img {
	width: 5em;
	display: flex;
	margin: 0 auto;
}

.footer input::placeholder {
	color: rgba(255, 255, 255, 0.4);
}

.input-container {
	display: flex;
	gap: 5px;
}

.input-container button {
	background-color: #71AE4D !important;
	border-radius: 50px !important;
	border: none;
	width: 40px;
	height: 40px;
	color: white;
}

.input-container button:disabled {
	opacity: 0.5;
}

.link-container {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	margin: 30px 0;
}

.grid-item {
	position: relative;
	padding: 10px;
}

.grid-item p {
	font-weight: 800;
	font-size: 20px;
	margin-bottom: 20px;
}

.grid-item > ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.grid-item > ul > li {
	margin: 10px 0;
}

.grid-item::before {
	content: "";
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	height: 1px;
	background-color: white;
}

.social-links {
	display: flex;
	align-items: center;
	gap: 50px;
	padding: 30px 40px;
	background-color: white;
	color: rgba(0, 0, 0, 0.7);
}

.social-links span:first-child {
	display: flex;
	gap: 10px;
}

.social-links p {
	margin: 0;
	margin-left: auto;
}

.social-links a:hover {
	cursor: pointer;
}

.footer input {
	outline: 2px solid;
}

a {
	color: white;
	text-decoration: none;
}

a:hover,
a:active {
	color: white;
	text-decoration: none;
}

@media (max-width: 900px) {
	.cover {
		padding: 20px;
	}

	.link-container {
		width: auto;
		grid-template-columns: 1fr;
		gap: 30px;
	}

	.grid-item {
		text-align: center;
	}

	.social-links {
		flex-direction: column;
		text-align: center;
	}
}

.lastrr{
	width: 100% !important;
}

.lastrrSvg svg {
	display: unset !important;
}