.language_container {
	background-color: rgba(255, 255, 255, 0.3);
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	color: white;
	gap: 5px;
	border-radius: 30px;
	box-shadow: rgb(204, 219, 232) 1px 1px 3px 0px inset,
		rgba(255, 255, 255, 0.5) -1px -1px 3px 1px inset;
	width: fit-content;
	margin: 0 auto inherit;
	padding: 10px 20px !important;
}

.language_container button {
	height: 40px;
	color: rgba(255, 255, 255, 0.7);
	width: 40px;
	display: grid;
	place-content: center;
	padding: 10px 15px;
	background-color: transparent;
	border-radius: 50px;
	border: none;
	outline: none;
	transition: all ease-in-out 0.5s;
	font-size: 14px;
}

.language_container .active {
	background-color: #71AE4D;
	border: 1px solid white;
	color: white;
}
