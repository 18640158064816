.news_modal_wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
	display: flex;
	justify-content: center;
	align-items: flex-start;
	z-index: 999;
}

.news_modal_content {
	background-color: #fff;
	margin-top: 30px;
	max-width: 550px;
	padding-top: 10px;
	padding-left: 20px;
	/* padding-bottom: 20px; */
	display: flex;
	flex-direction: row;
	border-radius: 8px;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
	position: relative;
	animation: slideDown 3s ease;
}

.text_content {
	background-image: url("../images/calling-imag.png");
	background-size: 300px 300px;
	background-position: 120% 20%;
	background-repeat: no-repeat;
	padding-right: 40%;
	padding-top: 40px;
	padding-bottom: 40px;
}

.image_content {
	height: 300px;
	width: 300px;
	object-fit: cover;
	border-radius: 8px;
}

.close_button {
	background-color: #71AE4D;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	border: none;
	font-size: 20px;
	cursor: pointer;
	position: absolute;
	right: 10px;
	top: 10px;
}

.text_content h2 {
	font-family: one-fonts-normal;
	width: 65%;
}

.text_content p {
	font-size: 14px;
	padding-right: 20%;
}

@media (max-width: 500px) {
	.text_content h2 {
		width: 45%;
	}
	
	.text_content p {
		width: 45%;
	}
}

.showOnmobile{
	display: none;
}

@media (max-width: 400px) {
	.text_content {
		background-image: unset !important;
	}

	.text_content p, .text_content h2, .nmoptewtrgdfrr {
		display: none;
	}

	.showOnmobile{
		display: block;
	}
}


.text_content .button-container > * {
	margin: 0 5px;
}

@media (max-width: 768px) {
	.news_modal_content {
		width: 90%;
	}

	.text_content {
		padding-right: 5%;
	}
}
