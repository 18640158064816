.privacy-main {
  background-color: #10172b;
}
.privacy {
  font-size: 70px;
  line-height: 40px;
  text-decoration: none;
}

@media (min-width: 990px) {
  .emp {
    margin-left: -250px;
  }
}
@media (max-width: 990px) {
  .emp {
    margin-left: -224px;
  }
}

.small-policy {
  font-size: 35px;
}
.md-policy {
  padding: 70px;
}
.empt {
  border-bottom: 5px solid white;
  width: 40px;
  text-align: center;
  margin-left: 5px;
}
.bometric {
  margin-top: -10px;
  height: 80px;
  width: 100%;
}
