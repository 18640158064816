.job_modal_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.job_modal_wrapper_content {
  background-color: #fff;
  padding: 30px 20px 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: relative;
  max-width: 700px;
}

.modal_close_btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  padding: 5px;
  background-color: red;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: grid;
  place-content: center;
  color: #fff;
}
