.hero_img {
	width: 100%;
	max-height: 500px !important;
}

.prime_bg1 {
	background-color: #10172b;
}

.prime_bg2 {
	background-color: #71AE4D;
}

.inherit_bg {
	background-color: inherit !important;
}

.fs_xsm {
	font-size: 0.9rem !important;
}

.prime_text1 {
	color: #71AE4D !important;
}

.dark_ash_text {
	color: #858685;
}

.accordion-button::after {
	background-image: url("../images/svg/plus.svg") !important;
}

.accordion-button:not(.collapsed)::after {
	background-image: url("../images/svg/minus-solid.svg") !important;
}

.prime_border {
	border: 1px solid #71AE4D;
}

.accordion-button:not(.collapsed) {
	background-color: #71AE4D !important;
}

.layout_container {
	margin: auto;
	font-weight: bold;
	padding: 0px;
}

.hotel_form {
	left: 10rem !important;
}

@media (min-width: 375px) {
	.layout_container {
		max-width: 100%;
	}
}

@media (min-width: 768px) {
	.layout_container {
		max-width: 730px;
	}
}
@media (min-width: 976px) {
	.layout_container {
		max-width: 976px;
	}
}
@media (min-width: 1200px) {
	.layout_container {
		max-width: 1260px;
	}
}
@media (min-width: 1500px) {
	.layout_container {
		max-width: 1800px;
	}
}

@media (max-width: 767px) {
	.min_auto {
		margin: 1rem auto !important;
	}
	.hotel_form {
		margin-top: 2rem;
		position: relative !important;
		top: 0px !important;
		left: 0px !important;
		transform: translateY(0%) !important;
	}
}

@media (max-width: 457px) {
	.mobile_modal {
		width: 320px !important;
	}
}
